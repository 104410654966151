<template>
	<div class="product">
		<banner :picS="bannerS"></banner>
		<div class="widthBox">
			<div class="productTitle">
				<titleOne id="product_1" en='Product center' :title="lang.menu.product"></titleOne>
			</div>
			<div class="tabS acea-row row-center">
				<div @click="proChange(index,item.id)" v-for="(item,index) in category" class="label" :class="proIndex==index?'active':''">{{item.name}}</div>
			</div>
			<ul class="proS">
				<li class="acea-row" v-for="item in proList">
					<router-link class="acea-row" :to="'/pro_detail?id='+item.id">
						<el-image :src="item.main_figure" class="pic"></el-image>
						<div class="cont">
							<h3>
								<template v-for="tt in item.index_remarks">{{tt}}<br/></template>
							</h3>
							<span></span>
							<p>{{item.enproduct}}</p>
							<div class="info">{{item.product}}</div>
							<div class="more">
								{{lang.public.more}}
								<img class="icon" src="@/assets/icon_08.png" alt="">
							</div>
						</div>
					</router-link>
				</li>
			</ul>
			<el-pagination v-if="total>10" :page-size="pageSize" :current-page="page" @current-change="joinChange" layout="prev, pager, next" :total="total"></el-pagination>
		</div>
		
	</div>
</template>

<script>
	import banner from '@/components/banner.vue'
	import titleOne from '@/components/titleOne.vue'
	export default {
		name: 'product',
		components:{
			banner,titleOne
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
			lang() {
				return this.$store.state.lang
			},
		},
		data:function() {
			return {
				proIndex:0,
				proList:[],
				category:[],
				page:1,
				pageSize:10,
				total:1,
			}
		},
		created() {
			this.getData()
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		methods:{
			getData(id){
				this.$api.get('home/index/productCenter',{
					page:this.page,
					pageSize:this.pageSize,
					category_id:id
				}).then(res=>{
					this.proList = res.data.productList
					this.category = res.data.product_category
					this.total = res.data.total
				})
			},
			proChange(index,id){
				if(this.proIndex == index) return
				this.proIndex = index
				this.proList=[]
				this.getData(id)
			},
			joinChange(e){
				this.page = e
				this.getData()
			}
		}
	}
</script>

<style lang="scss">
	.product{
		width:100%;
		.productTitle{
			margin-top:60px;
		}
		.el-pagination{
			margin:50px auto 0;
			text-align: center;
			button{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-weight: 500;
				background-color: rgba($color: #1D2A55, $alpha:1);
				color: #FFF;
				i{
					font-size:24px;
				}
				&:disabled {
				    color: #1D2A55;
				    background-color: rgba($color: #1D2A55, $alpha:0.1);
				    cursor: not-allowed;
				}
			}
			li{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-size:24px;
				margin:0 5px;
				font-weight: 500;
				color:rgba($color: #1D2A55, $alpha: 0.4);
				&.active{
					color:rgba($color: #1D2A55, $alpha: 1);
				}
			}
		}
		.tabS{
			margin-top:50px;
			.label{
				width:300px;
				height:80px;
				line-height:80px;
				text-align: center;
				box-sizing: border-box;
				color:#1D2A55;
				border:1px solid #1D2A55;
				font-size:32px;
				margin:0 35px;
				cursor: pointer;
				&.active{
					color:#FFF;
					background:#1D2A55;
				}
			}
		}
		.proS{
			li{
				border:1px solid #1D2A55;
				border-radius:50px;
				overflow: hidden;
				margin-top:50px;
			}
			.pic{
				width:820px;
			}
			.cont{
				width:538px;
				padding: 180px 50px 30px 50px;
				box-sizing: border-box;
				h3{
					font-size:40px;
					line-height:64px;
					color:#1D2A55;
				}
				span{
					width:60px;
					height:4px;
					background:#1D2A55;
					display: block;
					margin-top:40px;
				}
				p{
					font-size:18px;
					color:#1D2A55;
					margin-top:25px;
				}
				.info{
					font-size:24px;
					color:#1D2A55;
					margin-top:18px;
				}
				.more{
					display:inline-block;
					width:160px;
					height:50px;
					border:1px solid #1D2A55;
					color:#1D2A55;
					text-align: center;
					line-height:50px;
					font-size:18px;
					margin-top:100px;
					position: relative;
					cursor: pointer;
					.icon{
						position: absolute;
						top:50%;
						right:-54px;
						width:77px;
						transform: translate(0,-50%);
					}
				}
			}
		}
	}
	
	@media screen and(max-width:1400px) {
		.product{
			.tabS{
				.label{
					width:260px;
					height:72px;
					font-size:28px;
					line-height:72px;
				}
			}
			.proS{
				.pic{
					width:55%;
				}
				.cont{
					width:45%;
					padding: 80px 30px 30px 30px;
				}
			}
		}
	}
	@media screen and(max-width:1200px) {
		.product{
			.tabS{
				.label{
					width:240px;
					height:60px;
					font-size:24px;
					line-height:60px;
				}
			}
			.proS{
				.pic{
					width:55%;
				}
				.cont{
					width:45%;
					padding: 60px 30px 30px 30px;
					h3{
						font-size:32px;
					}
					p{
						font-size:16px;
					}
					.info{
						font-size:20px;
					}
					.more{
						margin-top:60px;
					}
				}
			}
		}
	}
	@media screen and(max-width:768px) {
		.product{
			.el-pagination{
				button{
					line-height:32px;
					height:32px;
					i{
						font-size:20px;
					}
				}
				li{
					line-height:32px;
					height:32px;
					font-size:16px;
				}
			}
			
			.productTitle{
				margin-top:20px;
			}
			.tabS{
				margin-top:20px;
				.label{
					flex:1;
					margin:0;
					font-size:14px;
					height:36px;
					line-height:36px;
					margin-left:10px;
					&:first-child{
						margin-left:0;
					}
				}
			}
			.proS{
				li{
					margin-top:20px;
					border-radius:10px;
					.pic{
						height: max-content;
					}
					.cont{
						padding:30px 10px 10px;
						h3{font-size:14px;line-height:20px;}
						span{
							margin-top:12px;
						}
						p{
							margin-top:10px;
							font-size:12px;
						}
						.info{
							margin-top:0px;
							font-size:12px;	
						}
						.more{
							margin-top:10px;
							height:24px;
							line-height:24px;
							width:60px;
							font-size:12px;
							img{
								display: none;
							}
						}
					}
				}
			}
		}
	}
</style>
